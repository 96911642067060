<template>
    <div  v-if="showMenu">
        <div class="nav-menu">
            <div class="menu-item pointer" v-for="item in navList" :key="item.name" @click="clickMenu(item)">
                <img :src="selectMenu==item.name?item.url:item.wurl" alt="">
                <div>{{item.name}}</div>
            </div>
        </div>
        <div v-if="isMobile&&showMenu" class="mask" @click="closeMask"></div>
    </div>
</template>

<script>
import navList from '@/utils/navMenu'
import { getClassList } from "@/api/games";
import { isMobile } from "@/utils/api";
import eventBus from '@/utils/eventBus'
    export default {
        data() {
            return {
                navList:[],
                selectMenu:'Hot',
                showMenu:false,
                isMobile:false,
            }
        },
        mounted() {
            this.isMobile = isMobile();
            this.getclasslist();
            eventBus.$on('showmenu',i=>{                
                this.showMenu = i;
            })
        },
        methods: {
            async getclasslist(){
                let navlist = await getClassList();
                navlist.forEach(item => {
                    navList.forEach(e=>{
                        if(item.key_name==e.name){
                            this.navList.push(e)
                        }
                    })
                });
            },
            closeMask(){
                this.showMenu = false;
                eventBus.$emit('showfenlei',false);
            },
            clickMenu(item){
                this.selectMenu = item.name;
                this.showMenu = false;
                eventBus.$emit('showfenlei',false);
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
                if(this.$route.path=='/selectGame'){
                    eventBus.$emit('searchgame',{
                        id:'more',
                        key:item.name
                    });
                }else{
                    this.$router.push({
                        path: '/selectGame',
                        query:{
                            id:'more',
                            key:item.name
                        }
                    })
                }
            }
        },
    }
</script>

<style lang="less" scoped>
@media (min-width: 768px) {
    .nav-menu{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        height:100vh;
    }
}
@media (max-width: 768px) {
    .nav-menu{
        position: fixed;
        top: 50px;
        left: 0;
        z-index: 999;
        height: calc(100vh - 50px);
    }
}
.noselect{
    -moz-user-select:none;/*火狐*/

    -webkit-user-select:none;/*webkit浏览器*/

    -ms-user-select:none;/*IE10*/

    -khtml-user-select:none;/*早期浏览器*/

    user-select:none;
}
.pointer{
    cursor: pointer;
}
.nav-menu{
    &::-webkit-scrollbar {
        width: 0;
    }
    width: 168px;
    overflow: auto;
    background: #504BA9;
    .menu-item{
        width: 116px;
        margin: 27px auto;
        display: flex;
        align-items: center;
        img{
            width: 27px;
            height: 27px;
        }
        div{
            margin-left: 11px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 22px;
            text-align: left;
            font-style: normal;
        }
    }
}
.mask{
    position: fixed;
    right: 0;
    z-index: 99999;
    width: calc(100vw - 168px);
    height: calc(100vh - 50px);
    background: rgba(0,0,0,0.5);
}
.noselect{
    -moz-user-select:none;/*火狐*/

    -webkit-user-select:none;/*webkit浏览器*/

    -ms-user-select:none;/*IE10*/

    -khtml-user-select:none;/*早期浏览器*/

    user-select:none;
}
.pointer{
    cursor: pointer;
}
</style>