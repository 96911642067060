export default [
    {
        name: 'Hot',
        url: require('@/assets/nclass/Hot.png'),
        surl: require('@/assets/class/hot.png'),
        wurl: require('@/assets/wClass/Hot2.png')
    },
    {
        name: 'New',
        url: require('@/assets/nclass/New.png'),
        surl: require('@/assets/class/ic_new1.png'),
        wurl: require('@/assets/wClass/New2.png')
    },
    {
        name: 'Boys',
        url: require('@/assets/nclass/Boys.png'),
        surl: require('@/assets/class/ic_boys1.png'),
        wurl: require('@/assets/wClass/Boys2.png')
    },
    {
        name: 'Girls',
        url: require('@/assets/nclass/Girls.png'),
        surl: require('@/assets/class/ic_girls1.png'),
        wurl: require('@/assets/wClass/Girls2.png')
    },
    {
        name: 'Adventure',
        url: require('@/assets/nclass/Adventure.png'),
        surl: require('@/assets/class/ic_adventure1.png'),
        wurl: require('@/assets/wClass/Adventure2.png')
    },
    {
        name: 'Action',
        url: require('@/assets/nclass/Action.png'),
        surl: require('@/assets/class/ic_action1.png'),
        wurl: require('@/assets/wClass/Action2.png')
    },
    {
        name: 'Mini',
        url: require('@/assets/nclass/Mini.png'),
        surl: require('@/assets/class/ic_mini1.png'),
        wurl: require('@/assets/wClass/Mini2.png')
    },
    {
        name: 'Fighting',
        url: require('@/assets/nclass/Fighting.png'),
        surl: require('@/assets/class/ic_fighting1.png'),
        wurl: require('@/assets/wClass/Fighting2.png')
    },
    {
        name: 'Casual',
        url: require('@/assets/nclass/Casual.png'),
        surl: require('@/assets/class/ic_casual1.png'),
        wurl: require('@/assets/wClass/Casual2.png')
    },
    {
        name: 'Classic',
        url: require('@/assets/nclass/Classic.png'),
        surl: require('@/assets/class/ic_classic1.png'),
        wurl: require('@/assets/wClass/Classic2.png')
    },
    {
        name: 'Racing',
        url: require('@/assets/nclass/Racing.png'),
        surl: require('@/assets/class/ic_ldie1.png'),
        wurl: require('@/assets/wClass/Racing2.png')
    },
    {
        name: 'Popular',
        url: require('@/assets/nclass/Popular.png'),
        surl: require('@/assets/class/ic_popular1.png'),
        wurl: require('@/assets/wClass/Popular2.png')
    },
    {
        name: 'Board',
        url: require('@/assets/nclass/Board.png'),
        surl: require('@/assets/class/ic_Board.png'),
        wurl: require('@/assets/wClass/Board2.png')
    },
    {
        name: 'Eliminate',
        url: require('@/assets/nclass/Eliminate.png'),
        surl: require('@/assets/class/ic_Eliminate.png'),
        wurl: require('@/assets/wClass/Eliminate2.png')
    },
    {
        name: 'Parkour',
        url: require('@/assets/nclass/Parkour.png'),
        surl: require('@/assets/class/ic_parkour.png'),
        wurl: require('@/assets/wClass/Parkour2.png')
    },
    {
        name: 'Place',
        url: require('@/assets/nclass/Place.png'),
        surl: require('@/assets/class/ic_Place.png'),
        wurl: require('@/assets/wClass/Place2.png')
    },
    {
        name: 'Puzzle',
        url: require('@/assets/nclass/Puzzle.png'),
        surl: require('@/assets/class/ic_Puzzle.png'),
        wurl: require('@/assets/wClass/Puzzle2.png')
    },
    {
        name: 'Simulated',
        url: require('@/assets/nclass/Simulated.png'),
        surl: require('@/assets/class/ic_Simulated.png'),
        wurl: require('@/assets/wClass/Simulated2.png')
    },
    {
        name: 'Sports',
        url: require('@/assets/nclass/Sports.png'),
        surl: require('@/assets/class/ic_Sports.png'),
        wurl: require('@/assets/wClass/Sports2.png')
    },
    {
        name: 'Tower',
        url: require('@/assets/nclass/Tower.png'),
        surl: require('@/assets/class/ic_Tower.png'),
        wurl: require('@/assets/wClass/Tower2.png')
    }
]